import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

const QAPair = ({ question, answer }) => {
    return (
        <div>
            <div className="item" data-sal="slide-up" data-sal-easing="ease-in-cubic">
                {/* <i className="item__icon material-icons text-primary">{ feature.icon }</i> */}
                <div className="item__content">
                    <h3 className="item__title">{ question }</h3>
                    <div className="item__text" dangerouslySetInnerHTML={{ __html: answer.childMarkdownRemark.html }} />
                </div>
            </div>
        </div>
    )
}

const Questions = ({ contentModuleId }) => {

    const data = useStaticQuery(graphql`
        query { 
            allContentfulLayoutQuestions {
                edges {
                    node {
                        id
                        title
                        questionList {
                            question
                            answer {
                                childMarkdownRemark {
                                    html
                                }
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutQuestions.edges.find(edge => edge.node.id === contentModuleId);

    return (
        <section id="questions" className="questions section">
            <div className="container mx-auto">
                <h2 className="section__title text-center mb-16" data-sal="fade" data-sal-easing="ease-in-cubic">{ content.node.title }</h2>
                {
                    content.node.questionList.length > 0 && 
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        {
                            content.node.questionList.map( questionAndAnswer => (
                                <QAPair { ...questionAndAnswer } />
                            ))
                        }
                    </div>
                }
                
            </div>
        </section>
    );
};

export default Questions;