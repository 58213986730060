import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import { navigate } from "@reach/router";
import LocationSuggest from 'components/locationSuggest';

const Hero = ({ contentModuleId }) => {
    
    const data = useStaticQuery(graphql`
        query {
            allContentfulLayoutHero {
                edges {
                    node {
                        id
                        heading
                        subheading
                        description {
                            childMarkdownRemark {
                                html
                            }
                        }
                        ctaText
                        ctaUrl
                        image {
                            fluid(quality: 100) {
                                ...GatsbyContentfulFluid
                            }
                        }
                    }
                }
            }
        }
    `);

    const content = data.allContentfulLayoutHero.edges.find(edge => edge.node.id === contentModuleId);

    const [selectedLocation, setSelectedLocation] = useState({})

    const handleLocationSelect = (location) => {
        setSelectedLocation(location)
    }

    const onCTAClick = (e) => {
        e.preventDefault();
        const locationParam = encodeURI(selectedLocation.label);
        navigate(`/getstarted/uploadimages?location=${locationParam}`)
    }

    return (
        <section className="hero container section mx-auto pb-0 overflow-hidden h-auto md:h-160">
            <div className="hero__tagline md:pt-10 md:pl-16">
                <div className="hero__tagline-content-wrap">
                    <h2 className="hero__tagline-title" data-sal="fade">{ content.node.heading }</h2>
                    <p className="hero__tagline-subtitle" data-sal="fade" data-sal-delay="100">{ content.node.subheading }</p>
                    <div className="hero__tagline-text" data-sal="fade" data-sal-delay="200" dangerouslySetInnerHTML={{ __html: content.node.description.childMarkdownRemark.html }} />
                    <LocationSuggest
                        onLocationSelect={handleLocationSelect}
                        placeholder="Try a Major City"
                        wrapperClass="mt-6"
                    >
                        <a href={ content.node.ctaUrl }>
                            <button onClick={onCTAClick} className="bg-pink" data-sal="fade" data-sal-delay="300">{ content.node.ctaText }</button>
                        </a>
                    </LocationSuggest>
                </div>
            </div>
            <div className="hero__image">
                <img src={ content.node.image.fluid.src }  className="mx-auto" alt="Hero" data-sal="slide-right" data-sal-delay="400" data-sal-duration="500" />
            </div>
        </section>
    );
};

Hero.propTypes = {
    contentModuleId : PropTypes.string.isRequired
}

export default Hero;